import { isEqual } from 'lodash';
import queryString from 'query-string';
import React, { memo, useEffect, useState } from 'react';
import {
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  InputOnChangeData,
  Label
} from 'semantic-ui-react';
import { notifier } from 'utils';

import { tagsApi } from 'api/tag';
import { Topologies } from 'components';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { useRouter } from 'hooks/useRouter';
import { MainLayout } from 'layouts';
import { Tag } from 'types/Tags/tags';

import { TopologyActionContainer } from './TopologyActionContainer';

import './styles.less';

interface Filters {
  text: string;
}

export const TopologiesView: React.FC = memo(() => {
  const router = useRouter();
  const getInitialStateFromURL = (location: { search: string }) => {
    const params = new URLSearchParams(location.search);
    return {
      filterText: params.get('filter') || '',
      selectedTags:
        params
          .get('tags')
          ?.split(',')
          .filter(Boolean) || [],
      limit: Number(params.get('limit')) || DEFAULT_PAGE_SIZE,
      offset: Number(params.get('offset')) || 0
    };
  };
  const { filterText, selectedTags, limit, offset } = getInitialStateFromURL(
    router.location
  );

  const [filters, setFilters] = useState<Filters>({ text: filterText });
  const [tags, setTags] = useState<string[]>(selectedTags);
  const [searchText, setSearchText] = useState<string>('');

  const [originalTags, setOriginalTagsResponse] = useState<string[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);

  useEffect(() => {
    const currentQuery = queryString.parse(router.location.search, {
      arrayFormat: 'comma'
    });
    const hasParamsChanged =
      filters.text !== currentQuery.filter ||
      !isEqual(tags, currentQuery.tags) ||
      limit.toString() !== currentQuery.limit ||
      offset.toString() !== currentQuery.offset;

    if (hasParamsChanged) {
      const newQuery = { ...currentQuery };
      if (filters.text) newQuery.filter = filters.text;
      else delete newQuery.filter;
      if (!isEqual(tags, currentQuery.tags)) newQuery.tags = tags;
      if (limit) newQuery.limit = limit.toString();
      if (offset) newQuery.offset = offset.toString();

      router.history.push({
        pathname: router.pathname,
        search: queryString.stringify(newQuery, { arrayFormat: 'comma' })
      });
    }
  }, [
    filters.text,
    tags,
    limit,
    offset,
    router.location.search,
    router.history,
    router.pathname
  ]);

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const res = await tagsApi.getAll();
        const newTags = res.tags.map((tag: Tag) => `${tag.name}:${tag.value}`);
        setOriginalTagsResponse(newTags);
        setAllTags(newTags);
      } catch (err) {
        notifier.requestFailed(err);
      }
    };
    getDepartments();
  }, []);

  const filterTag = (value: string) => {
    if (!tags.includes(value)) {
      setTags([...tags, value]);
    }
  };

  const removeTagFilter = (value: string) => {
    if (tags.includes(value)) {
      setTags(tags.filter(tag => tag !== value));
    }
  };

  const onFilterTagsInput = (value: string) => {
    if (value.length > 0) {
      const text = value.toLowerCase();
      const searchItems = originalTags.filter(tag =>
        tag.toLowerCase().includes(text)
      );
      setAllTags(searchItems);
    } else {
      setAllTags(originalTags);
    }
  };

  const handleFilterChange = (text: string) => {
    setFilters({ ...filters, text });
  };

  const renderTagDropdown = () => (
    <Dropdown button icon='filter' data-testid='filter-dropdown'>
      <Dropdown.Menu>
        <Input
          icon='search'
          iconPosition='left'
          placeholder='Search Tags'
          className='search'
          value={searchText}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            data: InputOnChangeData
          ) => {
            setSearchText(data.value);
            onFilterTagsInput(data.value);
          }}
        />
        <Dropdown.Menu scrolling data-testid='tag-dropdown'>
          <Dropdown.Header icon='tags' content='Filter by tag' />
          {allTags.map(option => (
            <Dropdown.Item
              key={option}
              text={option}
              value={option}
              active={tags.includes(option)}
              onClick={(event, data) => {
                event.stopPropagation();
                filterTag(data.value as string);
              }}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
  return (
    <MainLayout className='cl-topologies-view'>
      <div className='caption'>
        <h2 className='header'>Topologies</h2>
      </div>

      <div className='actions'>
        <Form className='search-form'>
          <Input
            className='search-input'
            placeholder='Search topologies'
            value={filters.text}
            autoFocus
            onChange={e => handleFilterChange(e.target.value)}
            action={renderTagDropdown()}
          />
        </Form>
        <div className='flex-1' />
        <TopologyActionContainer />
      </div>
      <br />
      {tags.map(tag => (
        <Label key={tag} data-testid='selected-tag'>
          {tag}
          <Icon name='delete' onClick={() => removeTagFilter(tag)} />
        </Label>
      ))}
      <br />
      <Divider />
      <Topologies
        filter={filters.text}
        limit={limit}
        offset={offset}
        displayedColumns={[
          'topology name',
          'region',
          'creation time',
          'expiration time',
          'created by',
          'created for',
          'department',
          'tags',
          'status',
          'actions'
        ]}
        selectionType={'multi'}
        tag={tags}
      />
    </MainLayout>
  );
});
export { Topologies };
