import { isEmpty } from 'lodash';
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Loader } from 'semantic-ui-react';
import { notifier } from 'utils';
import { paginationUtils } from 'utils/pagination';

import { topologiesApi } from 'api/topology';
import { NoDataPlaceholder } from 'components';
import { SelectionType } from 'components/ContactsTable/SelectionType';
import { TopologiesTable } from 'components/TopologiesTable';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { useRouter } from 'hooks/useRouter';
import { useTemplates, useTopologies } from 'hooks/useTemplates';
import { Topology } from 'types';
import { ConfirmTopologyDelete } from 'views/Topologies/ConfirmTopologyDelete';

type Props = {
  readonly filter?: string | null;
  readonly offset?: number;
  readonly limit: number;
  readonly displayedColumns: string[];
  readonly selectionType: SelectionType;
  readonly owner?: number[];
  readonly createdForId?: number[];
  readonly tag?: string[];
  readonly basicNoDataPlaceholder?: boolean;
};
export const Topologies: React.FC<Props> = memo(
  ({
    filter,
    limit,
    offset,
    displayedColumns,
    selectionType,
    tag,
    basicNoDataPlaceholder,
    owner,
    createdForId
  }) => {
    const router = useRouter();
    const [activePage, setActivePage] = useState<number>(
      offset ? offset + 1 : 1
    );
    const [confirmDeleteModelOpen, setConfirmDeleteModelOpen] = useState(false);
    const [selectedTopology, setSelectedTopology] = useState<Topology | null>(
      null
    );

    const params = useMemo(
      () => ({
        filter,
        offset: offset ? offset : activePage - 1,
        limit,
        tag,
        paginated: true,
        owner,
        createdForId
      }),
      [filter, activePage, offset, limit, tag, owner, createdForId]
    );

    const templatesQuery = useTemplates();

    const { data, refetch, isLoading } = useTopologies(params);

    const updateActivePage = useCallback(
      (page: number) => {
        router.updateQueryParam('offset', page - 1);
        setActivePage(page);
      },
      [router]
    );

    const getTotalPages = useCallback(
      (totalTopologiesCount: number) => {
        const totalPages = paginationUtils.getTotalPages({
          pageSize: limit,
          totalCount: totalTopologiesCount
        });
        return totalPages;
      },
      [limit]
    );

    useEffect(() => {
      refetch();
    }, [offset, filter, limit, tag, refetch]);

    useEffect(() => {
      if (data && offset && offset >= getTotalPages(data?.total)) {
        updateActivePage(getTotalPages(data?.total));
      } else if (data && offset && offset < 0) {
        updateActivePage(1);
      }
    }, [offset, getTotalPages, data, updateActivePage]);

    const onDelete = async () => {
      if (!selectedTopology) return;

      try {
        await topologiesApi.remove(selectedTopology.uuid);
        notifier.success({
          message: `Topology ${selectedTopology.name} scheduled for deletion`
        });
        await refetch();
      } catch (err) {
        notifier.requestFailed(err);
      }
      setConfirmDeleteModelOpen(false);
    };

    if (
      (isLoading || templatesQuery.isLoading) &&
      (!data || !templatesQuery.data)
    ) {
      return (
        <Loader
          data-testid='loader'
          active
          inline='centered'
          className='padded'
        />
      );
    }

    if (isEmpty(data)) {
      return <NoDataPlaceholder basic={basicNoDataPlaceholder} />;
    }

    return (
      <Fragment>
        <TopologiesTable
          topologies={data.topologies || []}
          templates={templatesQuery.data || []}
          displayedColumns={displayedColumns}
          selectionType={selectionType}
          onExtendDuration={() => refetch()}
          onDelete={topology => {
            setSelectedTopology(topology);
            setConfirmDeleteModelOpen(true);
          }}
          setActivePage={updateActivePage}
          activePage={activePage}
          totalPages={getTotalPages(data.total)}
        />
        <ConfirmTopologyDelete
          selectedTopologies={selectedTopology ? [selectedTopology] : []}
          setOpen={setConfirmDeleteModelOpen}
          open={confirmDeleteModelOpen}
          onDelete={onDelete}
        />
      </Fragment>
    );
  }
);

Topologies.defaultProps = {
  limit: DEFAULT_PAGE_SIZE
};
