import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { notifier } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import { Template, Topology } from 'types';

import qs from 'qs';

export const useTemplates = (): UseQueryResult<Template[]> => {
  return useQuery<Template[]>('Templates', () => {
    return axios
      .get(apiPrefix(apiRoutes.templates), {
        params: { limit: 100 }
      })
      .then(response => (response.data ? response.data.templates : []))
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
};

interface TopologiesResponse {
  topologies: Topology[];
  total: number;
}

export const useTopologies = (params: {
  filter: string | null | undefined;
  offset: number;
  limit: number;
  tag: string[] | undefined;
  paginated: boolean;
  owner: number[] | undefined;
  createdForId: number[] | undefined;
}): UseQueryResult<TopologiesResponse> => {
  return useQuery<TopologiesResponse>('Topologies', () => {
    return axios
      .get(apiPrefix(apiRoutes.topologiesWithCreator), {
        params,
        paramsSerializer: param => {
          return qs.stringify(param, { arrayFormat: 'repeat' });
        }
      })
      .then(response => (response.data ? response.data : []))
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
};
